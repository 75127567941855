
// Fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-slider/dist/css/bootstrap-slider.css";
@import "~fullcalendar/dist/fullcalendar.css";
@import "~dropzone/dist/dropzone.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~ionicons/dist/css/ionicons-core.css";
@import "~ionicons/dist/css/ionicons.css";
@import "~simple-line-icons/css/simple-line-icons.css";
@import "~summernote/dist/summernote.css";
@import "~toastr/build/toastr.css";
@import "~fotorama/fotorama.css";
@import "~owl.carousel/docs/assets/owlcarousel/assets/owl.carousel.css";
@import "~owl.carousel/docs/assets/owlcarousel/assets/owl.theme.default.css";
@import "~dropzone/dist/dropzone.css";
@import "~summernote/dist/summernote.css";
@import "~chosen-js/chosen.css";
@import "~line-awesome/dist/css/line-awesome.css";

